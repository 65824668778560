// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-commitment-js": () => import("./../../../src/pages/commitment.js" /* webpackChunkName: "component---src-pages-commitment-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/TermsAndConditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/Blog/Blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-product-product-js": () => import("./../../../src/templates/Product/Product.js" /* webpackChunkName: "component---src-templates-product-product-js" */)
}

