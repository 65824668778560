module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WaterHill","short_name":"WaterHill","description":"WaterHill, votre outil de communication","lang":"fr","display":"standalone","icon":"src/assets/img/blueLogo.png","start_url":"/","background_color":"#fff","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a4b9ac5d70709692753165d4abecf753"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
